<template>
  <section class="management-area">
    <transition name="zoom-fade" mode="out-in">
      <router-view />
    </transition>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      
    }
  },
  computed: { },
  methods: { },
  beforeCreate() {},
  created() {},
  mounted() { }
};
</script>